var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"contact-us"},[_c('div',{staticClass:"container-new"},[_c('div',{staticClass:"contact-us__image"}),_c('div',{staticClass:"contact-us__form"},[_c('h2',{domProps:{"textContent":_vm._s(_vm.$t('Send us a message'))}}),_c('validation-observer',{ref:"form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendMessage)}}},[_c('validation-provider',{staticClass:"form-group form-group--horizontal",attrs:{"tag":"div","rules":"required","name":_vm.$t('User Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label t-nowrap",attrs:{"for":"username"},domProps:{"textContent":_vm._s(_vm.$t('User Name'))}}),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],staticClass:"form-control form-input--underline",attrs:{"id":"username","type":"text","placeholder":_vm.$t('Jonny')},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "username", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"form-group__error",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"form-group form-group--horizontal",attrs:{"tag":"div","rules":"required|email","name":_vm.$t('Email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"email"},domProps:{"textContent":_vm._s(_vm.$t('Email'))}}),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control form-input--underline",attrs:{"id":"email","type":"text","placeholder":_vm.$t('john.pitterson@gmail.com')},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"form-group__error",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"form-group mt-3",attrs:{"name":_vm.$t('Comment'),"rules":"max:500|required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"message"}},[_vm._v(" "+_vm._s(_vm.$t('Your message'))+" ")]),_c('div',{staticClass:"form-validation w-100"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.comment),expression:"form.comment"}],staticClass:"form-control",attrs:{"id":"message","name":"message","rows":"8","placeholder":_vm.$t('Add your message here')},domProps:{"value":(_vm.form.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "comment", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"form-group__error",domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('button',{staticClass:"btn btn--primary btn--send",class:{ 'btn--disabled': invalid || _vm.loading },attrs:{"disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('Send'))}})],1)]}}])})],1)]),_c('modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],on:{"close":_vm.closeModal}},[_c('h5',{staticClass:"mb-0",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('Your message has been successfully delivered!'))+" ")]),_c('div',{staticClass:"modal__content",attrs:{"slot":"content"},slot:"content"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/message_sent.svg"),"alt":""}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="contact-us">
    <div class="container-new">
      <div class="contact-us__image"></div>
      <div class="contact-us__form">
        <h2 v-text="$t('Send us a message')"></h2>
        <validation-observer
          v-slot="{ handleSubmit, invalid }"
          ref="form"
          tag="div"
        >
          <form @submit.prevent="handleSubmit(sendMessage)">
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              rules="required"
              :name="$t('User Name')"
            >
              <label
                for="username"
                class="form-label t-nowrap"
                v-text="$t('User Name')"
              ></label>
              <div
                class="form-validation"
                :class="{ 'is-invalid': errors.length }"
              >
                <input
                  id="username"
                  v-model="form.username"
                  type="text"
                  :placeholder="$t('Jonny')"
                  class="form-control form-input--underline"
                />
                <span
                  v-if="errors.length"
                  class="form-group__error"
                  v-text="errors[0]"
                ></span>
              </div>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              rules="required|email"
              :name="$t('Email')"
            >
              <label
                for="email"
                class="form-label"
                v-text="$t('Email')"
              ></label>
              <div
                class="form-validation"
                :class="{ 'is-invalid': errors.length }"
              >
                <input
                  id="email"
                  v-model="form.email"
                  type="text"
                  :placeholder="$t('john.pitterson@gmail.com')"
                  class="form-control form-input--underline"
                />
                <span
                  v-if="errors.length"
                  class="form-group__error"
                  v-text="errors[0]"
                ></span>
              </div>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="form-group mt-3"
              :name="$t('Comment')"
              rules="max:500|required"
              tag="div"
            >
              <label for="message" class="form-label">
                {{ $t('Your message') }}
              </label>
              <div class="form-validation w-100">
                <textarea
                  id="message"
                  v-model="form.comment"
                  class="form-control"
                  name="message"
                  rows="8"
                  :placeholder="$t('Add your message here')"
                ></textarea>
                <span
                  v-if="errors.length"
                  class="form-group__error"
                  v-text="errors[0]"
                ></span>
              </div>
            </validation-provider>
            <button
              class="btn btn--primary btn--send"
              :class="{ 'btn--disabled': invalid || loading }"
              :disabled="loading"
              v-text="$t('Send')"
            />
          </form>
        </validation-observer>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal">
      <h5 slot="title" class="mb-0">
        {{ $t('Your message has been successfully delivered!') }}
      </h5>
      <div slot="content" class="modal__content">
        <div>
          <img src="@/assets/img/message_sent.svg" alt="" />
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Modal from '@/components/modal/Modal';

export default {
  name: 'Contact',
  components: {
    Modal,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      form: {
        name: '',
        email: '',
        comment: '',
      },
      loading: false,
      isModalVisible: false,
    };
  },

  methods: {
    sendMessage() {
      this.loading = true;

      setTimeout(() => {
        // console.log('Message sent');

        this.resetForm();
        this.isModalVisible = true;
        this.loading = false;
      }, 2000);
    },

    resetForm() {
      this.form = {
        name: '',
        email: '',
        comment: '',
      };

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './contact.scss';
</style>
